import React from "react";

const Team2 = () => {
  return (
    <section className="team section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                My desktop is never empty.
              </h6>
              <h3 className="wow color-font">
                If I could add a 5th monitor to my desk I would.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="item cir md-mb50">
              <div className="img">
                <img src="/img/team/pprj1.jpg" alt="" />
              
                <div className="info">
                  <h6>Adobe</h6>
                  <span>Premiere Pro</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item cir md-mb50">
              <div className="img">
                <img src="/img/team/afx1.jpg" alt="" />
              
                <div className="info">
                  <h6>Adobe</h6>
                  <span>After Effects</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item cir sm-mb50">
              <div className="img">
                <img src="/img/team/blender1.jpg" alt="" />
               
                <div className="info">
                  <h6>Open Source</h6>
                  <span>Blender</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="item cir">
              <div className="img">
                <img src="/img/team/shell1.jpg" alt="" />
                <div className="info">
                  <h6>Microsoft/ Cisco...</h6>
                  <span>Various IT Tools</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team2;
